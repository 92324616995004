<template>
  <CCard>
    <CCardHeader>
      {{$t('Профиль')}}
    </CCardHeader>
    <CCardBody>
      <div v-if="model.tariff !== ''">
        <p>Ваш тарифный план &laquo;{{model.tariff_name}}&raquo; активен до {{model.tariff_expired}}.</p>
        <p>
          <CButton @click="disableAutoPay()" size="sm" color="danger">{{$t('Отключить продление')}}</CButton>
        </p>
      </div>

      <CRow v-if="model.tariff === ''">
        <CCol>
          <CCard>
            <CCardHeader>
              <strong>{{$t('Старт')}}</strong>
            </CCardHeader>
            <CCardBody>
              <ul>
                <li>База клиентов до 1000</li>
                <li>События в календаре до 2</li>
                <li>Персональная поддержка: Служба Заботы</li>
                <li>Консультации со специалистом: 1</li>
              </ul>
              <p>
                <strong>2 790</strong> рублей в месяц
              </p>

              <CButton @click="payTariff('start')" size="sm" color="info">{{$t('Оплатить')}}</CButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard>
            <CCardHeader>
              <strong>{{$t('Бизнес')}}</strong>
            </CCardHeader>
            <CCardBody>
              <ul>
                <li>База клиентов до 10 000</li>
                <li>События в календаре до 10</li>
                <li>Персональная поддержка: Служба Заботы</li>
                <li>Консультации со специалистом: 2</li>
              </ul>
              <p>
                <strong>8 580</strong> рублей в месяц
              </p>

              <CButton @click="payTariff('business')" size="sm" color="info">{{$t('Оплатить')}}</CButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CForm @submit.prevent="save(model)">
        <CInput
            :placeholder="$t('Имя')"
            autocomplete="name"
            v-model.lazy="model.name"
            required
        >
          <template #prepend-content><CIcon name="cil-user"/></template>
        </CInput>
        <CInput
            placeholder="Email"
            autocomplete="email"
            v-model.lazy="model.email"
            required
            type="email"
        >
          <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
        </CInput>
        <CInput
            :placeholder="$t('Телефон')"
            autocomplete="phone"
            v-model.lazy="model.phone"
            required
            type="phone"
            pattern="[\-\(\)+ 0-9]{10,25}"
        >
          <template #prepend-content><CIcon name="cil-phone"/></template>
        </CInput>
        <CInput
            :placeholder="$t('Пароль (не менее 6 символов)')"
            type="password"
            v-model.lazy="model.pass"
            autocomplete="new-password"
            value=""
        >
          <template #prepend-content><CIcon name="cil-lock-locked"/></template>
        </CInput>
        <CInput
            :placeholder="$t('Повторите пароль')"
            type="password"
            v-model.lazy="model.pass2"
            class="mb-4"
            autocomplete="new-password"
        >
          <template #prepend-content><CIcon name="cil-lock-locked"/></template>
        </CInput>
        <router-link to="/calendar/list"><CButton type="submit" size="sm" color="dark">{{ this.$t('Отмена') }}</CButton></router-link>
        <CButton type="submit" size="sm" color="info">{{$t('Сохранить')}}</CButton>
      </CForm>
      <notifications group="profile" />
    </CCardBody>
  </CCard>
</template>

<script>

import axios from '@/modules/axios'

export default {
  name: 'Profile',
  data() {
    return {
      model: {
        name: '',
        email: '',
        phone: '',
        pass2: '',
        pass: ''
      }
    }
  },
  created() {
    this.getProfile();
    this.payCheck();
  },
  methods: {
    disableAutoPay() {
      axios.post('/user/check-payments', {}, {
        headers: {'content-type': 'application/json'}
      }).then((res) => {
        console.log(res.data.data);
      }).catch((error) => {
        this.$notify({group: 'profile', text: error.response.data.message, type: 'error'})
      })
    },
    payTariff(tariff) {
      axios.post('/user/set-tariff', {tariff}, {
        headers: {'content-type': 'application/json'}
      }).then((res) => {
        if (res.data.data.url) {
          document.location.href = res.data.data.url;
        }
      }).catch((error) => {
        this.$notify({group: 'profile', text: error.response.data.message, type: 'error'})
      })
    },
    payCheck() {
      axios.post('/user/check-payments', {}, {
        headers: {'content-type': 'application/json'}
      }).then((res) => {
        console.log(res.data.data);
      }).catch((error) => {
        this.$notify({group: 'profile', text: error.response.data.message, type: 'error'})
      })
    },
    save() {
      axios.post('/user/set-profile', this.model, {
        headers: {'content-type': 'application/json'}
      }).then(() => {
        this.$router.push('/calendar/list')
      }).catch((error) => {
        this.$notify({group: 'profile', text: error.response.data.message, type: 'error'})
      })
    },
    getProfile () {
      axios.get('/user/get-profile').then((res) => {
        this.model = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'profile', text: error.response.data.message, type: 'error' })
      })
    },
  }
}
</script>
<style lang="scss">
form button {
  margin-right: 2em;
}
</style>
